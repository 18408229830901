body {
  //overflow-x: hidden;
  text-align: justify;
}

blockquote {
  font-style: italic;
  padding: .5rem 0 0 1rem;
  border-left: solid 1rem $gray-200;
}

cite {
  display: block;
  text-align: right;
  margin-bottom: 1rem;
  &:before,
  &:after {
    display: inline-block;
    content: "\2014";
    margin-left: .5rem;
  }
}

h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-serif;
}

h2 {
  color: $primary;
}
h3 {
  color: $secondary;
}
h4 {
  color: $dark;
}

img {
  display: inline-block;
  max-width: 100%;
}

.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

.underlined,
a.underlined {
  text-decoration: underline;
}

.xs-only-jump {
  margin-bottom: 1rem;
  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
}

.main-content {
  margin-bottom: 2rem;
  @include media-breakpoint-up(md) {
    margin-bottom: 5rem;
  }
}

.pagination {
  font-family: $headings-font-family-ALT;
  font-weight: 500;
  font-size: calc(12/16) * 1rem;
  @include media-breakpoint-up(md) {
    font-size: calc(14/16) * 1rem;
    letter-spacing: .5px;
  }
}

.treeview {
  display: block;
  text-align: left;
  font-size: 80%;
  header {
    font-weight: bold;
    display: block;
    border-bottom: solid 1px $gray-300;
    padding-bottom: .5rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    color: $primary;
  }
  a {
    display: block;
    color: $black;
    transition: $transition-base;
    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
  nav > ul {
    list-style-type: none;
    display: block;
    margin: 0;
    padding: 0;
    & > li {
      display: block;
      margin: 0 0 .5rem;
      padding: 0 0 0 1rem;
      border-left: solid 1px transparent;
      transition: $transition-base;
      & > a {
        font-weight: 500;
      }
      &.active > a {
        color: $primary;
      }
      ul {
        list-style-type: none;
        margin: .5rem 0 0 0;
        padding: 0;
        display: block;
        li {
          display: block;
          margin: 0 0 .5rem 1.25rem;
          padding: 0;
          position: relative;
          &:before {
            display: inline-block;
            position: absolute;
            left: -1rem;
            content: "\25cf";
            color: $primary;
          }
          &.active a {
            color: $primary;
          }
        }
      }
      &:hover {
        //background-color: $light;
        border-left-color: $gray-300;
      }
    }
  }
}

.carousel-control-prev,
.carousel-control-next {
  color: $primary;
}
.carousel-control-prev:before {
  @include fontawesome;
  content: "\f053";
}
.carousel-control-next:before {
  @include fontawesome;
  content: "\f054";
}
.min-height {
  @include media-breakpoint-up(md) {
    min-height: 43.75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
  }
}
.icon404 {
  width: 4rem;
  height: 4rem;
  margin-left: auto;
  margin-right: auto;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMDAgMTAwIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnIGlkPSJMYXllcl8xIj4NCjwvZz4NCjxnIGlkPSJMYXllcl8yIj4NCgk8ZyBkaXNwbGF5PSJub25lIj4NCgkJPHBhdGggZGlzcGxheT0iaW5saW5lIiBkPSJNNjUuOSwxMGwxMi43LDEyLjdWOTBIMjEuNFYxMEg2NS45IE03MC4xLDBIMTEuNHYxMDBoNzcuM1YxOC41TDcwLjEsMEw3MC4xLDB6Ii8+DQoJPC9nPg0KCTxnPg0KCQk8cG9seWdvbiBmaWxsPSIjMzMzMzMzIiBwb2ludHM9Ijg4LjYsMTAwLjIgMTEuNCwxMDAuMiAxMS40LDYxLjUgMjEuNCw2MS41IDIxLjQsOTAuMiA3OC42LDkwLjIgNzguNiw2Mi41IDg4LjYsNjIuNSAJCSIvPg0KCTwvZz4NCgk8Zz4NCgkJPHBvbHlnb24gZmlsbD0iIzMzMzMzMyIgcG9pbnRzPSI4OC42LDQyIDc4LjYsNDIgNzguNiwyMi40IDY2LjQsMTAgMjEuNCwxMCAyMS40LDQxLjEgMTEuNCw0MS4xIDExLjQsMCA3MC42LDAgODguNiwxOC4zIAkJIi8+DQoJPC9nPg0KCTxnPg0KCQk8cG9seWxpbmUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMzMzMzMzIiBzdHJva2Utd2lkdGg9IjEwIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHBvaW50cz0iNC4zLDM5IDIyLjMsNDUgNDAuMiwzOSA1OC4xLDQ1DQoJCQk3Ni4xLDM5IDk0LDQ1IAkJIi8+DQoJPC9nPg0KCTxnPg0KCQk8cG9seWxpbmUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMzMzMzMzIiBzdHJva2Utd2lkdGg9IjEwIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHBvaW50cz0iNC4zLDU5IDIyLjMsNjUgNDAuMiw1OSA1OC4xLDY1IA0KCQkJNzYuMSw1OSA5NCw2NSAJCSIvPg0KCTwvZz4NCjwvZz4NCjwvc3ZnPg0K') no-repeat 50% 50%;
  background-size: cover;
  content: "";
}

.pagination-placeholder {
  text-align: center;
  margin: 1em 0;
  .pagination {
    justify-content: center;
    align-items: center;
    li {
      margin: 0 .25em;
      @include media-breakpoint-up(md) {
        margin: 0 1em;
      }
    }
  }
}

p.more {
  text-align: center;
  @include media-breakpoint-up(sm) {
    text-align: right;
  }
  @include media-breakpoint-up(lg) {
    text-align: center;
  }
}
.more a,
.more2 span {
  display: inline-block;
  text-transform: uppercase;
  font-size: calc(12/16) * 1rem;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1.6666rem;
  padding: .125rem 1rem 0;
  border: solid 2px $primary;
  border-radius: 1rem;
  background: $white;
  transition: $transition-base;
  &:before {
    @include fontawesome;
    content: "\f061";
  }
  &:hover {
    color: $white;
    background: $primary;
    text-decoration: none;
  }
}
.more2 a:hover span {
  color: $white;
  background: $primary;
  text-decoration: none;
}
p.more.more-alternate {
  margin-bottom: .25em;
  a {
    font-size: calc(10/16) * 1rem;
    line-height: 1.6666em;
  }
}