@mixin fontawesome {
  font-family: "FontAwesome";
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  line-height: 1;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: none;
}
