// _search-results.scss

.search-results {
  display: block;
  margin-top: 3rem;
  h2 {
    color: inherit;
  }
  & > ul {
    list-style-type: none;
    margin: 2rem 0 4rem;
    padding: 0;
    display: block;
    & > li {
      display: block;
      margin: 0 0 1rem;
      padding: 0 0 .5rem;
      border-bottom: solid 1px $gray-300;
    }
  }
  article {
    display: block;
    text-align: left;
    padding-left: 2rem;
    & > div > a {
      font-size: 1.5rem;
      font-weight: 500;
      display: inline-block;
      margin-bottom: .5rem;
      clear: both;
      margin-left: -2rem;
      width: calc(100% + 2rem);
    }
    date {
      display: inline;
      &:after {
        display: inline-block;
        content: "\2015";
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
    .type {
      display: inline;
      font-style: italic;
    }
    .themes,
    .tags,
    dl,
    dd,
    ul,
    li,
    footer a {
      display: inline;
      margin: 0;
      padding: 0;
    }
    ul {
      list-style-type: none;
      display: inline-block;
      max-width: 90%; // let the space for the icon on the left
      text-align: justify;
      @include media-breakpoint-up(md) {
        max-width: 96%;
      }
    }
  }
  footer {
    display: inline-block;
    font-size: 80%;
    margin-top: .5rem;
  }
  article dl.themes {
    &:before {
      vertical-align: top;
      margin-right: .5rem;
      @include fontawesome;
      content: "\f114\a0";
    }
  }
  article dl.tags {
    &:before {
      vertical-align: top;
      margin-right: .5rem;
      @include fontawesome;
      content: "\f02c\a0";
    }
  }
  footer li:after {
    display: inline;
    content: "\2c\a0";
  }
  footer li:last-child:after {
    display: none;
    content: "";
  }
}
