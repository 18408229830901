// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.


//
// Color system
//

// stylelint-disable
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #f3f3f3;
$gray-300: #d3d3d6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$blue:    #0d2234;
$red:     #c80c0f;

$primary:       $red;
$secondary:     $blue;
$third:         $gray-300;
$light:         $gray-100;
$dark:          $gray-800;

$theme-colors: (
   primary: $primary,
   secondary: $secondary,
   success: $green,
   info: $cyan,
   warning: $yellow,
   danger: $red,
   light: $gray-100,
   dark: $gray-800
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-caret:              true;
$enable-rounded:            false;
$enable-transitions:        true;
$enable-grid-classes:       true;
$enable-print-styles:       true;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// stylelint-disable
$spacer: 1rem;

// Body
//
// Settings for the `<body>` element.
$body-bg:                   $white;
$body-color:                $gray-900;

// Links
//
// Style anchor elements.
$link-color:                $primary;
$link-decoration:           none;
$link-hover-color:          darken($link-color, 15%);
$link-hover-decoration:     underline;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
        sm: 540px,
        md: 740px,
        lg: 960px,
        xl: 1170px
);
@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12;
$grid-gutter-width:           2rem;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:              normal;
$line-height-sm:              normal;

$border-color:                $gray-300;

// $border-radius:               .25rem;
// $border-radius-lg:            .3rem;
// $border-radius-sm:            .2rem;

// $component-active-color:      $white;
// $component-active-bg:         theme-color("primary");

// $caret-width:                 .3em;

$transition-base:             all .2s ease-in-out;
$transition-fade:             opacity .15s linear;
$transition-collapse:         height .35s ease;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      "Cabin", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-serif:           'Alegreya Sans', serif;
$font-family-base:            $font-family-serif;
// stylelint-enable value-keyword-case

$line-height-base:            normal;

$font-size-base:              1.125rem;

$h1-font-size:                $font-size-base * 2.5;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.5;
$h4-font-size:                $font-size-base * 1.25;
$h5-font-size:                $font-size-base * 1.125;
$h6-font-size:                $font-size-base;

$headings-margin-bottom:      calc($spacer / 2);
$headings-font-family:        "Cabin", "Helvetica Neue", Arial, sans-serif;
$headings-font-family-ALT:    "Montserrat", "Helvetica Neue", Arial, sans-serif;
$headings-font-weight:        300;
$headings-line-height:        normal;
$headings-color:              inherit;

$display1-size:               6rem;
$display2-size:               5.5rem;
$display3-size:               4.5rem;
$display4-size:               3.5rem;

$display1-weight:             300;
$display2-weight:             300;
$display3-weight:             300;
$display4-weight:             300;
$display-line-height:         $headings-line-height;

$lead-font-size:              ($font-size-base * 1.25);
$lead-font-weight:            300;

$small-font-size:             80%;

// Printing
$print-page-size:                   a4;
$print-body-min-width:              map-get($grid-breakpoints, "lg");

// Pagination

//$pagination-padding-y:              .5rem !default;
//$pagination-padding-x:              .75rem !default;
//$pagination-padding-y-sm:           .25rem !default;
//$pagination-padding-x-sm:           .5rem !default;
//$pagination-padding-y-lg:           .75rem !default;
//$pagination-padding-x-lg:           1.5rem !default;
//$pagination-line-height:            1.25 !default;
$pagination-color:                  $primary;
$pagination-bg:                     transparent;
$pagination-border-width:           0;
$pagination-border-color:           transparent;

$pagination-hover-color:            $secondary;
$pagination-hover-bg:               $gray-100;
$pagination-hover-border-color:     transparent;

$pagination-active-color:           $black !important;
$pagination-active-bg:              transparent;
$pagination-active-border-color:    transparent;
