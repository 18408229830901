// Required for BootStrap
@import '~bootstrap4/scss/_functions.scss';
@import '~bootstrap4/scss/_mixins.scss';
@import '~bootstrap4/scss/_variables.scss';
@import "custom-bootstrap-variables";

// Optional
@import '~bootstrap4/scss/_reboot.scss';
@import '~bootstrap4/scss/_type.scss';
@import '~bootstrap4/scss/utilities/_flex.scss';
@import '~bootstrap4/scss/_grid.scss';
@import '~bootstrap4/scss/_pagination.scss';
@import '~bootstrap4/scss/_carousel.scss';
@import '~bootstrap4/scss/_forms.scss';
@import '~bootstrap4/scss/_buttons.scss';
@import '~bootstrap4/scss/_alert.scss';

@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Palanquin:100,200,300,400,500,600,700|Alegreya:400,400i,500,500i,700,700i,800,800i,900,900i|Cabin:400,400i,500,500i,600,600i,700,700i|Alegreya+Sans:100,100i,300,300i,400,400i,500,500i,700,700i,800,800i,900,900i');

// Ilec app
@import 'mixins';
@import 'common';
@import 'off-canvas-menu';
@import 'carousel';
@import 'top-header';
@import 'regular-jumbo';
@import 'breadcrumb';
@import 'footer';
@import 'article';
@import 'home';
@import 'forms';
@import 'custom-select2';
@import 'search-results';
@import 'subscribers';
@import 'cookies';
@import 'magazine-brands';