// Search form
#main-search-form {
  @include media-breakpoint-up(md) {
    label {
      margin-bottom: 0;
    }
  }
  .form-group.row .form-group.row {
    margin-bottom: 0;
  }
  .submit-button-container {
    margin-top: 2rem;
    @include media-breakpoint-up(md) {
      margin-top: 3rem;
    }
  }
}
input#text {
  border-color: $primary;
}

// Contact form
#contact--trigger {
  margin-bottom: 2rem;
  //text-align: center;
  select {

  }
}
.container-form-contact {
  @include media-breakpoint-up(lg) {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
  legend {
    display: none;
  }
  label {
    @include media-breakpoint-up(md) {
      margin-top: .6666rem;
    }
  }
  .col-md-4 {
    @include media-breakpoint-up(md) {
      text-align: right;
    }
  }
  .mandatory label:after {
    display: inline-block;
    content: "\2a";
    color: $danger;
    margin-left: .25em;
  }
  p.mandatory {
    font-size: 80%;
    text-align: right;
  }
  p.mandatory:before {
    display: inline-block;
    content: "\2a";
    color: $danger;
    margin-right: .25em;
  }
}
