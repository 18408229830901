.regular-jumbo {
  min-height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $black;
  background-color: $white;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../medias/img/slide1.jpg);
  background-position: 50% 50%;
  .container {
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  h1,
  .h1 {
    line-height: 1;
    font-size: calc(46/16) * 1rem;
    font-weight: 200;
    text-transform: uppercase;
    letter-spacing: .5rem;
    margin-bottom: 0.5rem;
    font-family: $headings-font-family-ALT;
  }
}
.jumbo-trame {
  height: 3.6666rem;
  content: "";
  background: transparent url(../medias/img/trame-jumbo.svg);
  background-size: 1300px;
}
