ul.subscribers {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  text-align: center;
  flex-wrap: wrap;
  align-items: stretch;
  li {
    display: flex;
    width: 50%;
    flex-direction: column;
    padding: 1rem;
    justify-content: flex-end;
    border: solid 1px transparent;
    transition: $transition-base;
    @include media-breakpoint-up(md) {
      width: calc(100% / 3);
    }
    @include media-breakpoint-up(lg) {
      width: calc(100% / 6);
    }
    &:hover {
      border-color: $gray-400;
    }
  }
  img {
    display: block;
    max-width: 75%;
    max-height: 2rem;
    width: auto;
    height: auto;
    transition: $transition-base;
    &:hover { // in case there is no link, manage an :hover state anyway
      filter: gray; /* IE6-9 */
      -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
      filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
    }
  }
  a {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: flex-end;
    color: $black;
    transition: $transition-base;
    &:hover {
      color: $primary;
      text-decoration: none;
      img {
        filter: gray; /* IE6-9 */
        -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
        filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
      }
    }
  }
  span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 3rem;
    line-height: 1;
    font-weight: 300;
    font-size: calc(14/16) * 1rem;
  }
  figure {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
  }
}
