// _.home.scss
.home-carrousel {
  margin-bottom: 2rem;
}
.main-content.home {
  h2 {
    font-family: $headings-font-family-ALT;
    font-size: calc(18/16) * 1rem;
    font-weight: 500;
    color: $primary;
    text-transform: uppercase;
    letter-spacing: .2rem;
    @include media-breakpoint-up(md) {
      padding-bottom: 1rem;
      background: $white;
      margin: 0;
    }
  }
}
section.home-articles,
section.home-bonnes-pratiques {
  display: block;
  .articles {
    background: $white;
  }
  article {
    display: block;
    break-inside: avoid-column;
    padding-bottom: 1rem;
    background: $white;
    &:after {
      display: block;
      width: 100%;
      height: 1px;
      background: $gray-300;
      margin: 0;
      padding: 0;
      overflow: hidden;
      content: "";
    }
    &.no-border:after {
      display: none;
    }
    &.pb0 {
      padding-bottom: 0;
    }
    img {
      display: block;
      max-height: 25vh;
      margin-left: auto;
      margin-right: auto;
    }
    a,
    span.title {
      display: block;
      font-size: calc(22/16) * 1rem;
      font-weight: bold;
      color: $black;
    }
    span.title {
      margin-bottom: .5rem;
    }
    a:hover,
    a:hover span.title {
      color: $primary;
      text-decoration: none;
    }
    .placeholder > :last-child {
      margin-bottom: 1rem;
    }
  }
  p.more {
    background: $white;
    padding-bottom: 1rem;
    margin: 0 0 2rem;
    &.for-md {
      display: none;
      @include media-breakpoint-up(md) {
        display: block;
      }
    }
    &.not-for-md {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
    .margin-top-1 {
      margin-top: 1rem;
    }
  }
  p.more a {
    display: inline-block;
    text-transform: uppercase;
    font-size: calc(12/16) * 1rem;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 1.6666rem;
    padding: .125rem 1rem 0;
    border: solid 2px $primary;
    border-radius: 1rem;
    background: $white;
    transition: $transition-base;
    &:before {
      @include fontawesome;
      content: "\f061";
    }
    &:hover {
      color: $white;
      background: $primary;
      text-decoration: none;
    }
  }
  figure,
  .video-container {
    display: block;
    margin-bottom: 1rem;
  }
  .press figure {
    float: left;
    margin-right: 1rem;
  }
  .video-container {
    position: relative;
    padding-bottom: 56.25%; // video 16:9 ratio
    height: 0;
    overflow: hidden;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
section.home-bonnes-pratiques {
  .articles {
    @include media-breakpoint-up(md) {
      column-count: 1;
      column-gap: 0;
    }
  }
  article:after {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}
.col-home-bonnes-pratiques {
  @include media-breakpoint-up(md) {
    border-left: solid 1px $gray-300;
  }
}
.home-bonnes-pratiques {
  @include media-breakpoint-up(md) {
    height: 100%;
    background-size: 1300px 167px;
    background-position: left bottom;
  }
}

.les-grandes-marques {
  text-align: center;
  @include media-breakpoint-up(md) {
    margin-top: 3rem;
  }
}

// Entretien, Saga, Tribune area
.row-entretien-saga-tribune {
  margin-top: 3rem;
}

// "Bulletins" and "Revue des marques" area
.bulletins-and-revue.home {
  background: $gray-200;
  color: $black;
  padding-top: 1rem;
  padding-bottom: 1rem;
  @include media-breakpoint-up(lg) {
    text-align: left;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  p {
    margin-bottom: .25em;
  }
  section {
    display: block;
    height: 100%;
  }
  .header {
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 1.5rem;
    }
    h2 {
      font-size: calc(20/16) * 1rem;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 3px;
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        line-height: 1;
      }
      .archives {
        font-size: 1rem;
        text-transform: lowercase;
        font-weight: bold;
        letter-spacing: 1px;
      }
    }
  }
  .articles {
    @include media-breakpoint-up(lg) {
      height: calc(100% - 2.75rem); // 2.75rem = .header's calculated height
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      flex-wrap: nowrap;
    }
  }
  article {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $white;
    border-radius: .5rem;
    padding: 1.25rem 1.125rem .5rem;
    margin-bottom: 1rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }
  .pre-title {
    color: $primary;
    font-size: calc(12/16) * 1rem;
    text-transform: uppercase;
    letter-spacing: 3px;
    text-align: center;
    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }
  h3 {
    font-weight: bold;
    font-size: calc(22/16) * 1rem;
    letter-spacing: .5px;
    line-height: 1;
    text-align: left;
  }
  .abstract {
    text-align: justify;
    font-size: .75em;
  }
  img {
    width: auto;
    height: auto;
    max-width: 10rem;
    max-height: 12rem;
    margin-left: auto;
    margin-right: auto;
  }
  p.more {
    text-align: center;
    @include media-breakpoint-up(sm) {
      text-align: right;
    }
    @include media-breakpoint-up(lg) {
      text-align: center;
    }
  }
  p.more a {
    display: inline-block;
    text-transform: uppercase;
    font-size: calc(12/16) * 1rem;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 1.6666rem;
    padding: .125rem 1rem 0;
    border: solid 2px $primary;
    border-radius: 1rem;
    background: $white;
    transition: $transition-base;
    &:before {
      @include fontawesome;
      content: "\f061";
    }
    &:hover {
      color: $white;
      background: $primary;
      text-decoration: none;
    }
  }
  .revue {
    figure {
      display: block;
      text-align: center;
    }
    @include media-breakpoint-up(lg) {
      article {
        min-width: 100%;// IE11 hack for flexbox
        flex-direction: row;
        & > div {
          @include media-breakpoint-up(md) {
            order: 1;
            display: flex;
            flex-direction: column;
            margin-right: .5rem;
            justify-content: space-between;
          }
        }
        figure {
          @include media-breakpoint-up(md) {
            order: 2;
            margin-left: .5rem;
          }
          img {
            border: solid 1px $gray-300;
          }
        }
      }
    }
  }
}





// "Fonds documentaire" and "Evenements" area
.fonds-doc-evenements {
  display: block;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: $white;
  color: $black;
  @include media-breakpoint-up(md) {
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
  header {
    display: block;
    @include media-breakpoint-up(md) {
      margin-bottom: 1.5rem;
    }
    h2 {
      font-size: calc(20/16) * 1rem;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 3px;
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        line-height: 1;
      }
    }
    p {
      font-size: calc(12/16) * 1rem;
      font-weight: bold;
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        line-height: 1;
      }
    }
  }
  .fonds-doc-img {
    text-align: center;
    img {
      @include media-breakpoint-up(md) {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 12rem;
      }
    }
  }
  img.fonds-doc-img {
    margin: 0 auto 1rem;
    @include media-breakpoint-up(md) {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 12rem;
      float: left;
      margin: 0 1.5rem 1rem 0;
    }
  }
  a.fond-documentaire {
    display: block;
    font-weight: bold;
    font-size: calc(22/16) * 1rem;
    letter-spacing: .5px;
    line-height: 1;
    text-align: left;
    margin-bottom: 1.5rem;
    color: $black;
  }
  .abstract {
    text-align: justify;
    margin-bottom: 2rem;
  }
  p.more {
    text-align: center;
    @include media-breakpoint-up(sm) {
      text-align: right;
    }
    @include media-breakpoint-up(lg) {
      text-align: center;
    }
  }
  p.more a {
    display: inline-block;
    text-transform: uppercase;
    font-size: calc(12/16) * 1rem;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 1.6666rem;
    padding: .125rem 1rem 0;
    border: solid 2px $primary;
    border-radius: 1rem;
    background: $white;
    transition: $transition-base;
    &:before {
      @include fontawesome;
      content: "\f061";
    }
    &:hover {
      color: $white;
      background: $primary;
      text-decoration: none;
    }
  }
  .evenements {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: block;
      li {
        display: block;
        margin: 0 0 1.25rem;
        padding: 0;
      }
    }
    a.evenement {
      display: block;
      margin-bottom: .25rem;
      color: $black;
      font-size: 1.6875rem;
      font-weight: 400;
      line-height: 1;
      text-align: left;
    }
    p {
      span {
        display: block;
        margin-bottom: .25rem;
        font-size: calc(16/18) * 1rem;
      }
    }
    .place {
      text-transform: uppercase;
      letter-spacing: 2px;
      color: $primary;
      font-size: calc(12/16) * 1rem;
    }
    .carousel-indicators {
      bottom: -2rem;
    }
  }
}

section.marques-home {
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
    background: $white;
    color: $black;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .home-carrousel {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: table;
    width: 100%;
  }
  li {
    display: table-cell;
    margin: 0;
    padding: 0;
    width: calc(100% / 6);
    text-align: center;
  }
  img {
    width: auto;
    height: auto;
    max-width: 173px;
    max-height: 100px;
  }
  .carousel-control-prev,
  .carousel-control-next {
    color: $primary;
    font-size: 125%;
  }
  .carousel-control-prev {
    left: -10%;
  }
  .carousel-control-next {
    right: -10%;
  }
}
.ad {
  text-align: center;
  padding-bottom: 2rem;
  font-size: 11px;
  line-height: 1;
  img {
    max-width: 100%;
    height: auto;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
  }
}

