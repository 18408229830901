.article {
  display: block;
  margin-bottom: 3rem;
  padding-bottom: 1rem;
  border-bottom: solid 1px $gray-400;
  @include clearfix;
  .article-img {
    border: solid 1px $gray-200;
    @include media-breakpoint-up(md) {
      max-width: 40%;
      float: right;
      margin-left: 2rem;
      margin-bottom: 1rem;
    }
    img {
      margin-left: auto;
      margin-right: auto;
    }
  }
  header {
    display: block;
    margin-bottom: 1rem;
    text-align: left;
  }
  header h1,
  header a {
    font-size: 1.9rem;
    margin-bottom: 0;
    color: $primary;
    font-weight: 300;
    font-family: $headings-font-family;
    text-align: left;
    &:hover {
      text-decoration: none;
    }
  }
  .voice_brands_title_file {
    display: inline-block;
    font-size: 1.5rem;
    margin-bottom: 0;
    color: $primary;
    font-weight: 300;
    font-family: $headings-font-family;
    text-align: left;
    &:before {
      display: inline-block;
      font-family: "FontAwesome";
      content: "\f1c1";
      font-size: 1.5rem;
    }
    &:hover {
      text-decoration: none;
      color: $secondary;
    }
  }
  h2{
    font-size: 1.8rem;
  }
  p.surtitre {
    color: $gray-600;
    font-weight: 500;
    letter-spacing: 1px;
  }
  p.date {
    color: $gray-600;
    font-family: $headings-font-family-ALT;
    font-weight: 500;
    white-space: nowrap;
    text-align: right;
    margin-bottom: .5rem;
    font-weight: 600;
  }
  .chapo {
    color: $gray-800;
    padding-bottom: 1rem;
    font-weight: 500;
    font-size: 1.25rem;
  }

  address {
    text-align: right;
    font-style: italic;
    color: $gray-600;
    &:before {
      display: inline;
      content: "\2014\a0\a0";
    }
    &:after {
      display: inline;
      content: "\a0\a0\2014";
    }
  }
  footer {
    display: block;
    font-family: $headings-font-family-ALT;
    font-size: calc(11/16) * 1rem;
    font-weight: 500;
    color: $gray-500;
    margin-top: 3rem;
    a {
      color: $gray-600;
      transition: $transition-base;
    }
    a:hover {
      text-decoration: none;
      color: $primary;
    }
    .docs {
      font-size: 1.125rem;
      font-weight: 400;
      font-family: $font-family-base;
    }
    .categories,
    .themes,
    .tags,
    .docs {
      display: inline;
      p {
        display: none;
      }
      ul {
        list-style-type: none;
        margin: 0 0 1rem;
        padding: 0;
        display: block;
        @include media-breakpoint-up(md) {
          display: inline-block;
        }
        &:before {
          display: inline-block;
          font-family: "FontAwesome";
          font-size: 1rem;
        }
        li {
          display: inline-block;
          &:after {
            display: inline;
            content: "\2c\a0\a0";
          }
          &:last-child:after {
            content: "\a0\a0\20";
          }
        }
      }
      &:after {
        @include media-breakpoint-up(md) {
          display: inline-block;
          content: "\2014\a0\a0";
        }
      }
      &:last-child:after {
        display: none;
      }
    }
    .categories {
      ul {
        &:before {
          content: "\f0e8\a0";
        }
      }
    }
    .themes {
      ul {
        &:before {
          content: "\f114\a0";
        }
      }
    }
    .tags {
      ul {
        &:before {
          content: "\f02c\a0";
        }
      }
    }
    .docs {
      ul {
        &:before {
          content: ""; // icons for docs are managed one by one, not globally
        }
      }
    }
    .docs {
      ul {
        li {
          a {
            display: inline-block;
            color: $primary;
            &:before {
              display: inline-block;
              font-family: "FontAwesome";
              content: "\f15b";
              font-size: 1rem;
            }
            &:hover {
              color: $secondary;
            }
          }
          a[href*=".pdf"]:before {
            content: "\f1c1";
          }
        }
      }
    }
  }
}

.article-aside {
  display: block;
  h3 {
    font-family: $headings-font-family-ALT;
    font-size: calc(18/16) * 1rem;
    font-weight: 500;
    color: $primary;
    text-transform: uppercase;
    letter-spacing: .2rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 2rem;
    }
  }
  header {
    margin-bottom: 1rem;
    text-align: left;
    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
  a {
    display: block;
    font-weight: bold;
    color: $black;
    font-size: calc(22/16) * 1rem;
    transition: $transition-base;
    margin-bottom: 1rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
    &:hover {
      text-decoration: none;
      color: $primary;
    }
  }
  p {
    font-size: calc(14/16) * 1rem;
  }
  header p {
    font-family: $headings-font-family-ALT;
    white-space: nowrap;
    margin-bottom: 0;
    font-size: calc(11/16) * 1rem;
    color: $gray-600;
    font-weight: 600;
    @include media-breakpoint-up(lg) {
      margin-left: 2rem;
    }
  }
}
section.article-aside article {
  display: block;
  padding-bottom: .5rem;
  margin-bottom: 1.5rem;
  border-bottom: solid 1px $gray-400;
  @include clearfix;
  .article-img {
    border: solid 1px $gray-200;
    text-align: center;
    @include media-breakpoint-up(md) {
      max-width: 25%;
      float: left;
      margin-right: 2rem;
      margin-bottom: 1rem;
    }
    img {
      margin-left: auto;
      margin-right: auto;
    }
  }
  p {
    font-size: 1rem;
  }
  img.gauche,
  img[style="float: left;"],
  img[style="float: left"],
  img[style="float:left;"],
  img[style="float:left"] {
    margin-right: 1rem;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 1rem;
  }
  img.droite,
  img[style="float: right;"],
  img[style="float: right"],
  img[style="float:right;"],
  img[style="float:right"] {
    margin-left: 1rem;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 1rem;
  }
}
.press-review-article-link {
  font-size: 160%;
}
