.carousel-indicators {
  bottom: -.5rem;
  @include media-breakpoint-up(md) {
    bottom: 1rem;
  }
  li {
    width: 11px;
    height: 11px;
    margin-right: .75rem;
    margin-left: .75rem;
    text-indent: -9999em;
    background-color: rgba($primary,.05);
    border: solid 2px $primary;
    transition: $transition-base;
    border-radius: 100%;
  }
  .active {
    background-color: $primary;
  }
}
.jumbo-carousel .carousel-item {
  background-color: $white;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: auto 100%;
  min-height: 20vh;
  &.active {
    display: flex;
  }
  @include media-breakpoint-up(md) {
    min-height: 375px;
  }
  background-image: url(../../../public/assets/img/carousel-home/slide1.jpg);// Default
  &.item1 {
    background-image: url(../../../public/assets/img/carousel-home/slide1.jpg);
  }
  &.item2 {
    background-image: url(../../../public/assets/img/carousel-home/slide2.jpg);
  }
  &.item3 {
    background-image: url(../../../public/assets/img/carousel-home/slide3.jpg);
  }
  &.item4 {
    background-image: url(../../../public/assets/img/carousel-home/slide4.jpg);
  }
  .container {
    display: flex;
    min-height: 100%;
    height: 20vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      height: 375px;
    }
  }
  a {
    display: flex;
    min-height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
    color: $black;
    background-color: rgba($black, 0);
    transition: $transition-base;
    &:hover {
      color: $primary;
      text-decoration: underline;
    }
  }
  span {
    display: block;
    text-align: center;
    margin-bottom: 1rem;
  }
  span.logo {
    width: calc(260px / 3);
    height: calc(164px / 3);
    text-indent: -9999rem;
    @include media-breakpoint-up(md) {
      width: 260px;
      height: 164px;
      margin-bottom: 1.5rem;
    }
    background: url(../../../public/assets/img/logo-ilec-full.svg) no-repeat 50% 50%;
    background-size: cover;
  }
  a .title {
    font-family: $headings-font-family-ALT;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 80%;
    @include media-breakpoint-up(md) {
      font-size: 2rem;
      letter-spacing: .5rem;
    }
  }
}
.carousel-item.squared {
  .squared--container {
    display: flex;
    width: 16rem;
    height: 16rem;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  }
}
