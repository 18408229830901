hr.main-footer-hr {
  border: none;
  margin: 1rem 0 .5rem;
  padding: 0;
  height: 3rem;
  content: "";
  background: transparent url(../medias/img/trame-jumbo.svg);
  background-size: 1300px 167px;
}
.main-footer {
  font-family: $font-family-sans-serif;
  display: block;
  background: $third;
  color: $black;
  padding: 2rem 0;
  font-size: 1rem;
  text-align: left;
  address {
    display: block;
    line-height: 1.4em;
    strong {
      margin-bottom: 1.5rem;
    }
  }
  strong {
    display: block;
    line-height: 1.4em;
    &.logo {
      text-indent: -9999em;
      background: url("../../../public/assets/img/ilec-logo.svg") no-repeat 50% 50%/100% 100%;
      width: calc(90/16) * 1rem;
      height: calc(51/16) * 1rem;
      margin-bottom: .5rem;
    }
  }
  & > .container > .row > .col-md-2,
  & > .container > .row > .col-md-6 {
    @include media-breakpoint-up(md) {
      padding-top: calc(59/16) * 1rem;
    }
  }
  .social {
    ul {
      list-style-type: none;
      display: block;
      margin: 0;
      padding: 0;
    }
    li {
      display: inline-block;
      margin: 0;
      padding: 0;
    }
    a {
      display: block;
      color: $white;
      font-size: 1.5rem;
      transition: $transition-base;
      margin-right: .5rem;
      &:hover {
        color: $primary;
      }
    }
    li:last-child {
      a {
        margin-right: 0;
      }
    }
  }
  nav {
    font-size: calc(15/16) * 1rem;
    line-height: 1.4em;
    ul {
      list-style-type: none;
      display: block;
      margin: 0;
      padding: 0;
    }
    li {
      display: block;
      margin: 0 0 .5rem;
      padding: 0;
    }
    a {
      display: block;
      color: $black;
      transition: $transition-base;
      &:hover {
        color: $primary;
        text-decoration: none;
      }
    }
  }
  h2 {
    font-size: 1.5rem;
    margin-top: 2em;
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }
}
.la-lettre-teaser {
  img {
    width: 200px;
    height: auto;
    max-width: 100%;
    box-shadow: 0 0 20px rgba(0,0,0,.2);
    clear: both;
    margin-bottom: 1em;
  }
}
.text-teasing {
  font-family: $font-family-serif;
  font-weight: 300;
}