.magazine-brands {
  h1.all-magazines {
    margin-bottom: 4rem;
  }
  div.all-magazines {
    margin-bottom: 4rem;
    .item {
      margin-bottom: 2rem;
      padding-bottom: 1rem;
      border-bottom: solid 1px #6e6e6e;
    }
    a.cover-link {
      display: block;
      text-align: center;
      text-decoration: none;
      color: #6e6e6e;
      font-weight: bold;
      line-height: 1;
      .img {
        display: block;
        max-width: 165px;
        margin-left: auto;
        margin-right: auto;
      }
      img {
        margin-bottom: 1.5rem;
        margin-left: auto;
        margin-right: auto;
      }
      .num,
      .title {
        display: block;
      }
      .num {
        color: $primary;
        margin-bottom: .5em;
      }
    }
    p.edito-link {
      margin: 1rem 0 2rem;
      a {
        text-decoration: none;
      }
    }
    .item-content .text-right a {
      font-family: $font-family-serif;
      font-style: italic;
      font-size: 120%;
      text-decoration: none;
    }
  }
}
.gratuit,
.payant {
  position: relative;
  &:before {
    font-size: 1.25rem;
    display: block;
    float: right;
    width: 3rem;
    height: 1.25rem;
    border-radius: .625rem;
    background: $success;
    content: "\20ac";
    text-align: center;
    color: $white;
    font-size: 1.25rem;
    line-height: 1;
    margin-left: 1rem;
    margin-top: .25rem;
  }
}
.payant:before {
  background: $danger;
}
.gratuit:after {
  display: block;
  width: 2px;
  height: 1.25rem;
  content: "";
  position: absolute;
  right: 1.4rem;
  top: .25rem;
  background: $white;
  overflow: hidden;
  transform: rotate(45deg);
}
.rdm-archives {
  margin-top: 3.5rem;
  margin-bottom: 1rem;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
