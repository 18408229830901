// Custom Select2
.select2-container {
  display: block;
}
.select2-container--default {
  display: block;
  width: 100%;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5;
}
.select2-container--default .select2-selection--multiple {
  border: 1px solid $gray-400;
  border-radius: 0;
  min-height: calc(44/16) * 1rem;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin-top: .5rem;
}
