.cookies-alert {
  position: fixed;
  bottom: -10rem;
  left: 0;
  background: $white;
  color: $black;
  padding: 1rem;
  width: 100%;
  box-shadow: 0 0 1rem rgba($black,.25);
  z-index: 1031;
  transition: bottom .3s ease-in-out;
}
body {
  transition: margin-bottom .3s ease;
}
body.cookies-alert-visible .cookies-alert {
  display: block;
  bottom: 0;
}
body.cookies-alert-visible {
  .main-footer {
    padding-bottom: 12rem;
    @include media-breakpoint-up(md) {
      padding-bottom: 6rem;
    }
  }
}
/* graphic styles */
.cookies-alert p {
  line-height: 1.4em;
  margin-bottom: 0;
}

a.close-cookies-alert {
  background: #fff;
  border: 1px solid black;
  border-radius: 50%;
  color: black;
  display: block;
  float: right;
  font-size: 15px;
  height: 20px;
  line-height: 13px;
  text-align: center;
  width: 20px;
}
@include media-breakpoint-up(md) {
  a.close-cookies-alert {
    height: 15px;
    font-size: 10px;
    line-height: 15px;
    width: 15px;
  }
}
