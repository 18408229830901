.top-header {
  font-family: $headings-font-family-ALT;
  color: $white;
  background: $primary;
  .f-table {
    display: table;
    width: 100%;
  }
  .f-tr {
    display: table-row;
  }
  .f-td {
    display: table-cell;
    height: 5rem;
    vertical-align: middle;
    @include media-breakpoint-up(md) {
      width: 33.3333%;
    }
  }
}
.burger-col,
.logo-col,
.shortcuts-col {
  * {
    align-self: center;
  }
  a {
    color: $white;
    text-decoration: none;
    display: inline-block;
    transition: color .3s;
    &:hover {
      color: rgba($white, .7);
    }
  }
}
.burger-col {
  .lbl {
    display: none;
    @include media-breakpoint-up(md) {
      display: inline-block;
      font-weight: 400;
      line-height: 1;
      margin-bottom: -.125rem;
    }
  }
  .ilec-icon-burger {
    @include media-breakpoint-up(md) {
      font-size: 1.5rem;
    }
  }
}
.logo-col {
  text-align: center;
  a {
    display: inline-block;
    color: $white;
    line-height: 1;
    img {
      height: 1.75em;
    }
    @include media-breakpoint-up(md) {
      font-size: 1.5rem;
    }
  }
}
.shortcuts-col {
  text-align: right;
  a {
    display: inline-block;
    text-align: left;
    @include media-breakpoint-up(md) {
      padding-left: .5rem;
      padding-right: .5rem;
      font-size: 1.5rem;
    }
    .lbl {
      font-size: 1rem;
      display: inline-block;
      width: 1px;
      text-indent: 1px;
      overflow: hidden;
      transition: width .3s;
      margin-bottom: -1px;
    }
    &:hover {
      .lbl {
        @include media-breakpoint-up(lg) {
          opacity: 1;
          width: 6rem;
        }
      }
    }
  }
}
.shortcuts {
  display: block;
}
