.breadcrumb {
  font-family: $headings-font-family;
  display: block;
  font-weight: 500;
  font-size: calc(12/16) * 1rem;
  color: $primary;
  padding-top: .5rem;
  padding-bottom: .5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  a {
    color: $primary;
  }
}
