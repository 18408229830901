body {
  overflow-x: hidden;
}
$menu-transition: all .5s ease;
.side-nav {
  font-family: $headings-font-family-ALT;
  display: block;
  height: 100vh;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: $white;
  overflow-x: hidden;
  padding-top: 1rem;
  transition: $menu-transition;
  box-shadow: 0 0 1rem rgba($black, .25);
  text-align: left;
  padding: 1rem 0;
  @include media-breakpoint-up(md) {
    padding-top: 4rem;
  }
  .placeholder {
    width: 19rem;
    padding: 0 1rem;
  }
  ul {
    list-style-type: none;
    display: block;
    margin: 0;
    padding: 0;
    li {
      display: block;
      margin: 0;
      padding: 0;
    }
  }
  .placeholder {
    & > ul {
      margin: 0;
      padding: 0;
      ul {
        margin-left: 1rem;
      }
      & > li {
        border-left: solid 1px transparent;
      }
      & > li:hover {
        border-color: $primary;
      }
    }
  }
}
.side-nav a {
  padding: .5rem .5rem .5rem .5rem;
  text-decoration: none;
  font-size: 1rem;
  color: $gray-600;
  display: block;
  @include media-breakpoint-up(md) {
    font-size: 1.25rem;
  }
}
.side-nav a:hover{
  color: $white;
  background: $gray-900;
}
.side-nav--closebtn {
  display: block;
  position: absolute;
  top: .5rem;
  right: .5rem;
  font-size: 1.75rem;
}
.side-nav li li a {
  font-size: 1rem;
  color: $gray-800;
}

#main {
  width: 100%;
  display: block;
  position: absolute;
  left: 0;
  transition: $menu-transition;
}
